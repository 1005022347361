import React from 'react'
import { H1, H2, H5, Paragraph1 } from '@styles/Global.styles'
import { Grid, Icon } from 'semantic-ui-react'
import { CheckoutSelectionCard } from '@components/CheckoutSelectionCard'
import { PlateManufactureSelectionSubHeading, SelectedItem, UnSelectedItem } from './PlateManufactureSelection.styles'
import { CheckoutSelectionCardModel } from '@models/Checkout/CheckoutSelectionCard'
import { KpButton } from '@elements/index'
import theme from '@styles/theme'
import { navigate } from 'gatsby'
import { BasketResponseModel } from '@models/Basket/BasketResponseModel'
import { Checkout } from '@models/Checkout/Checkout'

export type PlateManufactureSelectionProps = {
    heading: string
    subHeading: string
    body: string
    manufactureNowCard: CheckoutSelectionCardModel
    ManufactureLaterCard: CheckoutSelectionCardModel
    basket: BasketResponseModel
    checkout: Checkout
    updateManufactureNow: (manufactureNow: boolean) => void
}

interface PlateManufactureSelectionState {
}

class MobilePlateManufactureSelection extends React.Component<PlateManufactureSelectionProps, PlateManufactureSelectionState>{
    constructor(props: PlateManufactureSelectionProps) {
        super(props);
    }

    selectManufactureNowOption = () => {
        this.props.updateManufactureNow(true);
    }

    selectManufactureLaterOption = (value: boolean) => {
        if(value != undefined){
            this.props.updateManufactureNow(!value);
        }
    }

    goBack = () => {
        let isRedesign = false;

        this.props.basket.basketLines.forEach((lineItem) => {
            if (lineItem.transactionTypeId === 2 || lineItem.transactionTypeId === 3) {
                isRedesign = true;
            }
        });

        if(this.props.checkout.isDealerOrder) {
            navigate('/select-a-style');
        }
        else if (isRedesign) {
            navigate('/shopping-cart');
        }
        else {
            navigate('/plate-owner-selection')
        }
    }

    continue = () => {
        if(this.props.checkout.isDealerOrder && !this.props.checkout.isAcceptStatement){
            navigate('/dealer-statement')
        } else {
            navigate('/personal-details');
        }
    }

    render() {

        const { heading, subHeading, body, manufactureNowCard, ManufactureLaterCard, checkout } = this.props;

        var MobileManufactureNowCard:CheckoutSelectionCardModel = {
            body: "",
            content: "",
            heading: manufactureNowCard.heading,
            items: manufactureNowCard.items
        };

        var MobileManufactureLaterCard:CheckoutSelectionCardModel = {
            body: ManufactureLaterCard.body,
            content: "",
            heading: ManufactureLaterCard.heading,
            items: ManufactureLaterCard.items
        };



        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <H1>
                            {heading}
                        </H1>
                        <Paragraph1 color={theme.mobileTheme.color}>
                            <strong>{subHeading}</strong>
                        </Paragraph1>

                        <div>
                            {
                                checkout.manufactureNow ? <H5>Make My Plates Now</H5> : <H5>Make My Plates Later</H5> 
                            }
                        </div><br/>

                        <Grid stretched>
                            {!checkout.manufactureNow &&
                            <Grid.Column mobile={16} tablet={16} computer={16}>
                                {ManufactureLaterCard.items.map((item) => {
                                   return <>
                                            <UnSelectedItem>
                                                <Icon name='check circle outline' size='big'/>
                                                <span style={{paddingLeft:'10px'}} dangerouslySetInnerHTML={{ __html: item.elements.title.value }}></span>
                                            </UnSelectedItem>
                                            <br/>
                                          </>
                                })}
                            </Grid.Column>}
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <CheckoutSelectionCard hideButton={true} card={checkout.manufactureNow ? MobileManufactureNowCard : MobileManufactureLaterCard} onSelect={this.selectManufactureLaterOption} manufactureSelection={true} expressCheckout={false} isSelected={checkout.manufactureNow}></CheckoutSelectionCard>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row verticalAlign='bottom'>
                    <Grid.Column width={16}>
                        <Grid reversed='mobile vertically'>
                            <Grid.Column mobile={16} tablet={8} computer={8} textAlign='right'>
                                <KpButton id="pms-next" fullWidth="mobile" buttonType='primary' color={theme.mobileTheme.buttonBgColor} textColor={theme.mobileTheme.buttonColor} onClick={this.continue}>NEXT</KpButton>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default MobilePlateManufactureSelection;