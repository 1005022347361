import React from "react"
import { graphql } from "gatsby";
import { Layout } from "@components/Layout";
import { mapToSEO, SEO } from "@components/SEO";
import { Grid } from "semantic-ui-react";
import { mapToCheckoutSelectionCard } from "@mappers/Checkout/CheckoutSelectionCard";
import OrderSummary from "@components/OrderSummary/OrderSummary";
import theme from "@styles/theme";
import { GenericBannerContainer } from "@components/GenericBannerContainer";
import { PlateManufactureSelection } from "@components/PlateManufactureSelection";

import { Record } from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from "redux"
import { currentCheckoutSelector } from "@redux/selectors/checkoutSelector";
import { currentBasketSelector } from "@redux/selectors/basketSelector";
import { updateManufactureNow, updateIsMultiPlate } from "@redux/actions";
import { getCurrentCheckout } from "@redux/localStorage/checkout";
import { isMobile } from "@utils/Helpers";
import MobilePlateManufactureSelection from "@components/Mobile/PlateManufactureSelection/PlateManufactureSelection";
import { NavHeader } from "@components/Mobile/NavHeader/NavHeader";
import MobileGenericBannerContainer from "@components/Mobile/GenericBannerContainer/GenericBannerContainer";

const PlateManufactureSelectionPageContainer = (props: any) => {

  const { elements } = props.data.allKontentItemPlateOwnerSelectionPage.nodes[0];
  const seo = mapToSEO(elements);
  const apiUrl = props.data.site.siteMetadata.apiUrl;
  const checkout = getCurrentCheckout();
  const manufactureNowCard = checkout.isDealerOrder ? mapToCheckoutSelectionCard(elements.plate_owner_card_dealer.linked_items[0].elements) : mapToCheckoutSelectionCard(elements.plate_owner_card.linked_items[0].elements);
  const ManufactureLaterCard = checkout.isDealerOrder ? mapToCheckoutSelectionCard(elements.gifting_card_dealer.linked_items[0].elements) : mapToCheckoutSelectionCard(elements.gifting_card.linked_items[0].elements);

  if(isMobile() === undefined){
    return null;
  }

  return (
    <Layout version="simple" currentStep={3} location={props.location}>
      <SEO {...seo} />
      <MobileGenericBannerContainer
        padding={{
          mobile: {
            top: 20,
            bottom: 40
          },
          desktop: {
            top: 40,
            bottom: 60
          }
        }}
        backgroundColor={isMobile() ? theme.mobileTheme.bgColor : theme.brand.colors.beige} paddingBottom={60} paddingTop={40}>
        <Grid stackable reversed='mobile tablet vertically' stretched>
          <Grid.Column mobile={16} tablet={16} computer={10}>
            {isMobile() ?
            <MobilePlateManufactureSelection
              heading={elements.heading.value}
              subHeading={elements.sub_heading.value}
              body={elements.body.value}
              manufactureNowCard={manufactureNowCard}
              ManufactureLaterCard={ManufactureLaterCard}
              basket={props.basket}
              checkout={checkout.isDealerOrder ? checkout : props.checkoutState}
              updateManufactureNow={props.updateManufactureNow} /> :
            <PlateManufactureSelection
              heading={elements.heading.value}
              subHeading={elements.sub_heading.value}
              body={elements.body.value}
              manufactureNowCard={manufactureNowCard}
              ManufactureLaterCard={ManufactureLaterCard}
              basket={props.basket}
              checkout={checkout.isDealerOrder ? checkout : props.checkoutState}
              updateManufactureNow={props.updateManufactureNow} />}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={6} floated='right'>
            {isMobile() ?
            <NavHeader link="/plate-owner-selection" info="Manufacture"/>:
            <OrderSummary isExpanded={false} isLayby={false} showSurcharge={false} basket={props.basket} apiUrl={apiUrl} />}
          </Grid.Column>
        </Grid>
      </MobileGenericBannerContainer>
    </Layout>);

}

const mapStateToProps = (state: Record<any>) => {
  const checkout = currentCheckoutSelector(state).toJS();
  const basket = currentBasketSelector(state).toJS();

  return {
    checkoutState: checkout,
    basket: basket
  };
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateManufactureNow(manufactureNow: boolean) {
    dispatch(updateManufactureNow(manufactureNow));
  }
})

const PlateManufactureSelectionPage = connect(mapStateToProps, mapDispatchToProps)(PlateManufactureSelectionPageContainer)


export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
    }
  }
  allKontentItemPlateOwnerSelectionPage(filter: {elements: {heading: {value: {eq: "Plate Manufacture"}}}}) {
    nodes {
      elements {
        body {
          value
        }
        heading {
          value
        }
        seo__nofollow {
          value {
            codename
            name
          }
        }
        seo__noindex {
          value {
            codename
            name
          }
        }
        seo__page_description {
          value
        }
        seo__page_title {
          value
        }
        sub_heading {
          value
        }
        url{
          value
        }
        plate_owner_card {
          linked_items {
            ... on KontentItemCheckoutSelectionCard {
              id
              elements {
                body {
                  value
                }
                heading {
                  value
                }
                content{
                  value
                }
                items {
                  linked_items {
                    ... on KontentItemCheckoutSelectionItem {
                      elements {
                        title {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        gifting_card {
          linked_items {
            ... on KontentItemCheckoutSelectionCard {
              id
              elements {
                body {
                  value
                }
                heading {
                  value
                }
                content{
                  value
                }
                items {
                  linked_items {
                    ... on KontentItemCheckoutSelectionItem {
                      elements {
                        title {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        plate_owner_card_dealer {
          linked_items {
            ... on KontentItemCheckoutSelectionCard {
              id
              elements {
                body {
                  value
                }
                heading {
                  value
                }
                content {
                  value
                }
                items {
                  linked_items {
                    ... on KontentItemCheckoutSelectionItem {
                      elements {
                        title {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        gifting_card_dealer {
          linked_items {
            ... on KontentItemCheckoutSelectionCard {
              id
              elements {
                body {
                  value
                }
                heading {
                  value
                }
                content {
                  value
                }
                items {
                  linked_items {
                    ... on KontentItemCheckoutSelectionItem {
                      elements {
                        title {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default PlateManufactureSelectionPage;
